import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react"
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import {
  DataGrid,
  useGridApiContext,
  GridCellEditStopReasons,
  GridToolbar,
  GridLogicOperator,
} from '@mui/x-data-grid';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const config = require('../config.json')

let editable = (headerName) => {return(
  <strong>
    {headerName}
    <span role="img" aria-label="edit">
      <EditIcon></EditIcon>
    </span>
  </strong>
)}

function getWLPrefix(params) {
  const prefix = `${params.row.Customer}`.split("-")[0];
  if (prefix === "default") {
    return "";
  }
  return prefix;
}

function isKeyboardEvent(event) {
  return !!event.key;
}

function EditTextarea(props) {
  const { id, field, value, colDef, hasFocus } = props;
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState();
  const [inputRef, setInputRef] = React.useState(null);
  const apiRef = useGridApiContext();

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event,
      );
    },
    [apiRef, field, id],
  );

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <InputBase
              multiline
              rows={4}
              value={valueState}
              sx={{ textarea: { resize: 'both' }, width: '100%' }}
              onChange={handleChange}
              inputRef={(ref) => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

EditTextarea.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The column field of the cell that triggered the event.
   */
  field: PropTypes.string.isRequired,
  /**
   * If true, the cell is the active element.
   */
  hasFocus: PropTypes.bool.isRequired,
  /**
   * The grid row id.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The cell value.
   * If the column has `valueGetter`, use `params.row` to directly access the fields.
   */
  value: PropTypes.string,
};

const multilineColumn = {
  type: 'string',
  renderEditCell: (params) => <EditTextarea {...params} />,
};

const columns = [
  {
    field: 'Monitoring',
    headerName: 'Monitoring',
    width: 80,
    editable: false,
    type: 'boolean'
  },
  {
    field: 'Profile_image',
    headerName: 'Profile Image',
    width: 120,
    renderCell: (params) => (
      <Avatar alt="Profile Image" src={params.row.Profile_image} />
    ),
  },
  {
    field: 'Customer',
    headerName: 'Customer',   
    width: 80,
    editable: false,
    valueGetter: getWLPrefix,
  }
  ,{
    field: 'Type',
    headerName: 'Type',
    type: 'singleSelect',
    
    width: 90,
    valueOptions: ['Individual', 'Business'],//autocomplete
    editable: false,
  },
  {
    field: 'Name',
    headerName: 'Name',
    width: 180,
    editable: false,
  },
  {
    field: 'Match_name',
    headerName: 'Match_name',
    width: 150,
    
  },
  {
    field: 'QR_Code',
    headerName: 'QR_Code',
    width: 80,
    
  },
  {
    field: 'Version',
    headerName: 'Version',
    width: 130,
    
  },
  {
    field: 'Create_time',
    headerName: 'Create_time',
    width: 150,
    valueFormatter: (params) => {
      const utcDate = new Date(params.value);
      const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
      const year = localDate.getFullYear();
      const month = String(localDate.getMonth() + 1).padStart(2, '0');
      const day = String(localDate.getDate()).padStart(2, '0');
      const hours = String(localDate.getHours()).padStart(2, '0');
      const minutes = String(localDate.getMinutes()).padStart(2, '0');
      const seconds = String(localDate.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
  {
    field: 'Status',
    headerName: 'Status', description:'Editable',
    width: 110,
    editable: true,	
    type: 'singleSelect',
    valueOptions:	['Open', 'True-positive', 'False-positive'],
    renderHeader: () => (<strong> {'Status'} <span role="img" aria-label="Editable">📝</span> </strong>),
  },
  {
    field: 'Risk',
    headerName: 'Risk', description:'Editable',
    width: 80,
    editable: true,	
    type: 'singleSelect',
    valueOptions:['High','Medium', 'Low'],
    renderHeader: () => (<strong> {'Risk'} <span role="img" aria-label="Editable">📝</span> </strong>),
  },
  {
    field: 'Remarks',
    headerName: 'Remarks', description:'Editable',
    width: 180,
    editable: true,
    ...multilineColumn,	
    renderHeader: () => (<strong> {'Remarks'} <span role="img" aria-label="Editable">📝</span> </strong>),
  },
  {
    field: 'Score',
    headerName: 'Score',
    type: 'number',
    width: 70,
    editable: false,
  },
  {
    field: 'Alert',
    headerName: 'Alert',
    type: 'singleSelect',
    width: 110,
    valueOptions: ['Version_changed', 'Score_increased'],//autocomplete
    editable: false,
  },
  {
    field:'Alert_date',
    headerName: 'Alert_date',
    width: 100,
    editable: false,
  }
];

const MonitorDateSlider = ({ monitorFreq }) => {
  const generateMarks = (monitorFreq) => {
    switch (monitorFreq) {
      case 'Daily':
        return Array.from({ length: 365 }, (_, index) => ({
          value: index,
          //label: new Date(new Date().getFullYear(), 0, index + 1).toLocaleDateString('default', { day: 'numeric', month: 'short' }),
        }));
      case 'Monthly':
        return Array.from({ length: 12 }, (_, index) => {
          const firstDayOfMonth = new Date(new Date().getFullYear(), index, 1);
          const dayOfYear = Math.floor((firstDayOfMonth - new Date(firstDayOfMonth.getFullYear(), 0, 0)) / 86400000);
          return {
            value: dayOfYear,
            label: `${firstDayOfMonth.toLocaleDateString('default', { month: 'numeric' })}-1`,
          };
        });
      case 'Quarterly':
        return [
          { value: 0, label: 'Jan 1' },
          { value: 90 + (isLeapYear(new Date().getFullYear()) ? 1 : 0), label: 'Apr 1' },
          { value: 182 + (isLeapYear(new Date().getFullYear()) ? 1 : 0), label: 'Jul 1' },
          { value: 275 + (isLeapYear(new Date().getFullYear()) ? 1 : 0), label: 'Oct 1' },
        ];
      case 'Semi-annually':
        return [
          { value: 0, label: 'Jan 1' },
          { value: 182 + (isLeapYear(new Date().getFullYear()) ? 1 : 0), label: 'Jul 1' },
        ];
      default:
        return [];
    }
  };

  const [value, setValue] = React.useState(0);

  const today = new Date(2024, 3, 1, 12, 0, 0);
  const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  const daysInYear = isLeapYear(today.getFullYear()) ? 366 : 365;
  const endOfFebruary = new Date(today.getFullYear(), 2, 0);
  const todayDayOfYear = Math.floor((today - new Date(new Date().getFullYear(), 0, 0)) / 86400000) - (today > endOfFebruary && isLeapYear(today.getFullYear()) ? 0 : 1);
  const localDate = today.toLocaleDateString('en-CA');

  const handleLabel = (value) => {
    if (value === todayDayOfYear) {
      return localDate; 
    }
    return null;
  };

  const marks = generateMarks(monitorFreq);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 39px)' }}>
      <Typography gutterBottom sx={{ marginLeft: '10px' }}>{`Monitor ${monitorFreq}`}</Typography>
      <Slider
        aria-label={`Monitor ${monitorFreq}`}
        value={todayDayOfYear}
        marks={marks}
        max={daysInYear - 1}
        color="secondary"
        getAriaValueText={handleLabel}
        valueLabelDisplay="on"
        valueLabelFormat={handleLabel}
        sx={{
          width: 'calc(100% - 39px)', // Adjust the width of the slider
          '& .MuiSlider-thumb': {
            width: 8,
            height: 8,
          },
        }}
      />
    </Box>
  );
};

export default function Monitor() {
  const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState({});

  const handleFilterTextChange = (field) => (event) => {
    setFilterText((prevFilterText) => ({
      ...prevFilterText,
      [field]: event.target.value,
    }));
  };

  const handleRowEditCommit = React.useCallback( (newRow) => {
    const updateRow = {...newRow, isNew: false};
    axios.patch(`${config.api.invokeUrl}/monitor`, { //api here
      Monitoring:newRow.Monitoring,
      User:newRow.User,
      Case:newRow.Case,
      Customer:newRow.Customer, 
      Type:newRow.Type,
      Name:newRow.Name,
      QR_Code:newRow.QR_Code,
      Version:newRow.Version, 
      Status:newRow.Status,
      Match_name:newRow.Match_name,
      Risk:newRow.Risk, 
      Remarks:newRow.Remarks,
      Score:parseInt(newRow.Score),
      Alert:newRow.Alert
    },{
      headers: {
        "content-type": "application/json",
        Authorization: `${user.signInUserSession.idToken.jwtToken}`
      }
    })
    .then((res) => {
      //console.log(res.data)
      if (res.status == 204 || res.status == 201) {
        setSnackbar({ children: 'Customer: ' + newRow.Customer + ' Name: ' + newRow.Name + ' attribute updated successfully.', severity: 'success' });
      }
      else {
        setSnackbar({ children:  'Customer: ' + newRow.Customer + ' Name: ' + newRow.Name + ' attribute updates failed.', severity: 'error' });
      }
    })
    .catch((err) => {
      setSnackbar({ children:  'Customer: ' + newRow.Customer + ' Name: ' + newRow.Name + ' attribute updates exception.', severity: 'error' });
      if (err.response.status === 401 || err.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(err);
    });
    return updateRow
  },[])

  const handleProcessRowUpdateError = React.useCallback((error) => {
  }, []);

  const [rows, setRows] = useState([]);
  const [monitorFreq, setMonitorFreq] = useState('Disable');

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        // Make an API call to get the profile data
        if (user) {
          const response = await axios.get(
            `${config.api.invokeUrl}/profile`,
            {
              headers: {
                "content-type": "application/json",
                Authorization: `${user.signInUserSession.idToken.jwtToken}`,
              },
            }
          );
  
          if (response.status === 200) {
            const profileData = response.data;
            setMonitorFreq(profileData.Monitor_freq);
          }
        }
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.reload(true);
        }
        console.error('Error fetching profile data in layout:', error);
        setSnackbar({
          children: 'Fail to fetch the profile data in layout',
          severity: 'warning',
        });
      }
    };

    async function fetchCases() {
      setIsLoading(true);
      await axios.get(`${config.api.invokeUrl}/monitor`,
      { 
        headers: {
          "content-type": "application/json",
          Authorization: `${user.signInUserSession.idToken.jwtToken}`
        },
        params:{
          "User":`${user.attributes.email}`
        }
      }) //call the testapi, get all items from database
      .then(function (response) { //make sure to call the correct stage+resource name link too (remidner to myself)
        //console.log(response.data);
        setRows(response.data);
        // response.data.forEach((note) => {insertRows(note)});
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          //signOut();
          //navigate("/login");
          window.location.reload(true);

        }
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }

    fetchCases();
    fetchProfileData();

    const interval = setInterval(()=>{
      fetchCases()
    }, 1*60*1000)
    return()=>clearInterval(interval)
  }, []);

  const getRowClassName = (params) => {
    if (params.row.Monitoring) {
      if (params.row.Status == 'True-positive') {
        return 'highlight-true-row';
      }
      return 'highlight-monitor-row';
    }
    return '';
  };
  const { Customer, English_name, Chinese_name } = useParams()  
  //const review = useLoaderData()
  return (
    <Box sx={{ margin:"auto", height: "auto", width: '100%' }}>
      {isLoading && <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
      <MonitorDateSlider monitorFreq={monitorFreq}/>
      <DataGrid
        sx={{
          ".highlight-monitor-row":{backgroundColor: "gainsboro"},
          ".highlight-true-row":{backgroundColor: "silver"},
        }}
        initialState={{
          sorting: { sortModel: [{field:'Create_time', sort: 'desc'}], },
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [Customer, English_name, Chinese_name],
              quickFilterExcludeHiddenColumns: true,
              quickFilterLogicOperator: GridLogicOperator.Or,
            },
          },
        }}
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
        pageSizeOptions={[10, 25, 50]}
        getRowId={(row) => row.Case} //give id
        processRowUpdate={handleRowEditCommit}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        getRowClassName={getRowClassName}
        experimentalFeatures={{ newEditingApi: true }}
        onCellEditStop={(params, event) => {
          if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
            return;
          }
          if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
            event.defaultMuiPrevented = true;
          }
        }}
        // Enable quick filter for all columns using slotProps
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          },
        }}
        // Set filterModel based on the filterText state
/*         filterModel={{
          items: [
            {
              field: 'Customer',
              operator: 'contains',
              value: filterText['Customer'] || '',
            },
            // ... add similar items for other columns as needed
          ],
        }}
 */      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}