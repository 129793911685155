import { useAuthenticator } from "@aws-amplify/ui-react"
import { useNavigate  } from 'react-router-dom'
import { API } from "aws-amplify"
import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
//install before import: npm install @mui/x-data-grid
import { DataGrid, GridColDef, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
//import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import axios from 'axios'
//import Notes from "./Notes"
//import {nationalityList} from './Lists/nationalityList'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import moment from "moment/moment"
import Box from '@mui/material/Box'
import { BarChart, Bar, Legend, CartesianGrid, XAxis, YAxis, Tooltip, Label, ResponsiveContainer } from 'recharts'
import {Card, CardContent, Typography, Grid} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const config = require('../config.json')

export default function Audit() {
  //const { route } = useAuthenticator((context) => [context.route]);
  const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
  const navigate = useNavigate();
  const [notes, setNotes] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [previousMonthData, setPreviousMonthData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setAdmin] = useState(false);
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [enableGoogle, setEnableGoogle] = useState(false);

  var showButton
  const searchColumns = [
    {
      field: 'Create_time',
      headerName: 'Create Time',
      width: 160,
      renderCell: (params) => {
        const utcDate = new Date(params.value);
        const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const seconds = String(localDate.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
    },
    { field: 'Audit', headerName: 'Audit', width: 80, hideable: false },
    { field: 'Customer', headerName: 'Customer', width: 100, hideable: false },
    { field: 'Type', headerName: 'Type', width: 100, hideable: false },
    { field: 'English_name', headerName: 'English Name', width: 200, hideable: false },
    { field: 'Chinese_name', headerName: 'Chinese Name', width: 150, hideable: false },   
    { field: 'Acuris_English', headerName: 'Acuris', disableExport: true, width: 110,
      renderCell: (params) => (
        <strong>
          {showButton = Boolean(params.value) && params.value.substring(0,7) === "Result/"}
          {!showButton && Boolean(params.value) && params.value}
          {!showButton && !Boolean(params.value) && Boolean(params.row.English_name) && params.row.Audit === "Search" && "processing..."}
          {showButton && 
            <Button 
              variant="contained" size="small"
              onClick={()=>downloadSearchResult(params.row.Acuris_English)}
            >
              Download
            </Button>}
        </strong>
      )
    },
    enableGoogle && { field: 'English_filename', headerName: 'English_filename', disableExport: true, width: 110,
      renderCell: (params) => (
        <strong>
          {showButton = Boolean(params.value) && params.value.substring(0, 7) === "Result/"}
          {!showButton && Boolean(params.value) && params.value}
          {!showButton && !Boolean(params.value) && Boolean(params.row.English_name) && params.row.Audit === "Search" && "processing..."}
          {showButton && 
            <Button 
              variant="contained" size="small"
              onClick={() => downloadSearchResult(params.row.English_filename)}
            >
              Download
            </Button>}
        </strong>
      )
    },
    enableGoogle && { field: 'Chinese_filename', headerName: 'Chinese_filename', disableExport: true, width: 110,
      renderCell: (params) => (
        <strong>
          {showButton = Boolean(params.value) && params.value.substring(0,7) === "Result/"}
          {!showButton && Boolean(params.value) && params.value}
          {!showButton && !Boolean(params.value) && Boolean(params.row.Chinese_name) && params.row.Audit === "Search" && "processing..."}
          {showButton && 
            <Button 
              variant="contained" size="small"
              onClick={() => downloadSearchResult(params.row.Chinese_filename)}
            >
              Download
            </Button>}
        </strong>
      )
    },       
    { field: 'Nationality', headerName: 'Nationality', width:80 },
    { field: 'DOB', headerName: 'DOB', width:90 },
    { field: 'Gender', headerName: 'Gender', width: 80 }    
  ].filter(Boolean); // This will remove falsy values (i.e., undefined) from the array;
  const urlstr = `${config.api.invokeUrl}`

  const downloadSearchResult = async (filename) => {
    if (filename == null)
      return;
    console.log("download", filename)
    const encodedFilename = encodeURIComponent(filename);
    const downloadUrl = `${urlstr}/kycic-search-result?file=${encodedFilename}`;
    //const link = document.createElement("a");
    var axios = require('axios');
    var config = {
      method: 'get',
      url: downloadUrl,
      headers: {
        "content-type": "application/json",
        Authorization: `${user.signInUserSession.idToken.jwtToken}`
      }
    };
    
    axios(config)
    .then(function (response) {
      const Url = response.data.URL;
      window.open(Url);
    })
    .catch(function (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(error);
    });
  }
  
  const fetchCases = async () => {
    setIsLoading(true);
    try {
      const requestInfo = {
        headers: {
          "content-type": "application/json",
          Authorization: `${user.signInUserSession.idToken.jwtToken}`
        },
        params:{"User":`${user.attributes.email}`}
      }
      const res = await axios.get(`${config.api.invokeUrl}/auditsearch`, requestInfo);
      const getNotesData = res.data.table_data;
      const getGraphData = res.data.graph_data;
      const getPreviousMonthData = res.data.previous_month_data;
      setNotes(getNotesData);
      setGraphData(getGraphData);
      setPreviousMonthData(getPreviousMonthData);
    } catch (err) { //consider using snackbar to say token expired 
      if (err.response.status === 401 || err.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(`An error has occurred: ${err}`);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      fetchCases(); // Your existing function
  
      const interval = setInterval(() => {
        fetchCases();
      }, 1 * 60 * 1000);
  
      return () => clearInterval(interval);
    };
  
    const fetchProfileData = async () => {
      try {
        // Make an API call to get the profile data
        if (user) {
          const response = await axios.get(
            `${config.api.invokeUrl}/profile`,
            {
              headers: {
                "content-type": "application/json",
                Authorization: `${user.signInUserSession.idToken.jwtToken}`,
              },
            }
          );
  
          if (response.status === 200) {
            const profileData = response.data;
            setAdmin(profileData.Admin);
            setEnableGoogle(profileData.Enable_Google)
          }
        }
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          //signOut();
          //navigate("/login");
          window.location.reload(true);
        }
        console.error('Error fetching profile data in layout:', error);
        setSnackbar({
          children: 'Fail to fetch the profile data in layout',
          severity: 'warning',
        });
      }
    };
  
    fetchData(); // Call the existing fetchData function
    fetchProfileData(); // Call the new fetchProfileData function  
  }, [user, isAdmin, enableGoogle])

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [logFile, setLogFile] = useState(null);
  const [showAdminGraph, setShowAdminGraph] = useState(false);
  var showButton;
  const onFileChange = (event) => {
    console.log("upload");    
    setSelectedFile(event.target.files[0]);
    const formData = new FormData();
    var date = new Date();
    var file_name = date.toISOString().slice(0, 19).replace(/[^0-9]/g, "")  + '-' + event.target.files[0].name;
    formData.append(
      user.attributes.email,
      event.target.files[0],
      file_name
    )
    var axios = require('axios');
    axios ({
      method: "post",
      url: urlstr,
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: `${user.signInUserSession.idToken.jwtToken}`
      }
    })
    .then (() => {
      setLogFile('Import/' + file_name.slice(0,-3)+'log');
      setSelectedFile(null);
      setFileUploaded(true);
      setShowAdminGraph(true);
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(err);
    });
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      const total = payload.reduce((sum, entry) => sum + entry.value, 0);
  
      return (
        <div className="custom-tooltip" style={{ background: 'rgba(255, 255, 255, 0.9)', padding: '5px', border: '1px solid #ccc' }}>
          <p>{label}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}`}
            </p>
          ))}
          <p>Total: {total}</p>
        </div>
      );
    }
  
    return null;
  };   
  return (
    <Box sx={{ margin:"auto", height: 'auto', width: '100%' }}>
      {isLoading && <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
      <Grid container spacing={2} justifyContent="center" marginBottom={2}>
          {enableGoogle && previousMonthData && (
            <Grid item xs={12} sm={6} md={enableGoogle ? 3 : 4}>
              <Card sx={{bgcolor:'#82ca9d', width: '100%', height: '200px'}} elevation={12}>
                <CardContent>                                
                  <Typography variant="h5" component="div" textAlign={"center"} color="text.secondary">
                    Google Total for {previousMonthData.Create_time} 
                  </Typography>
                  <Typography variant="h1" component="div" textAlign={"center"}>{previousMonthData ? previousMonthData.Google : 0}</Typography>                
                </CardContent>
              </Card>
            </Grid>
          )}
          {previousMonthData && (
          <Grid item xs={12} sm={6} md={enableGoogle ? 3 : 4}>
            <Card sx={{bgcolor:'#277A8C', width: '100%', height: '200px'}} elevation={12}>
              <CardContent>                
                <Typography variant="h5" component="div" textAlign={"center"} color="text.secondary">
                  Acuris Total for {previousMonthData.Create_time}
                </Typography>
                <Typography variant="h1" component="div" textAlign={"center"}>{previousMonthData ? previousMonthData.Acuris : 0}</Typography>                
              </CardContent>
            </Card>
          </Grid>
          )}
          {previousMonthData && (
          <Grid item xs={12} sm={6} md={enableGoogle ? 3 : 4}>
            <Card sx={{bgcolor:'#8884d8', width: '100%', height: '200px'}} elevation={12}>
              <CardContent>                  
                <Typography variant="h5" component="div" textAlign={"center"} color="text.secondary">
                  Monitor Total for {previousMonthData.Create_time}
                </Typography>
                <Typography variant="h1" component="div" textAlign={"center"}>{previousMonthData ? previousMonthData.Monitor : 0}</Typography>                             
              </CardContent>
            </Card>
          </Grid>
          )}
          {previousMonthData && (
          <Grid item xs={12} sm={6} md={enableGoogle ? 3 : 4}>
            <Card sx={{bgcolor:'#ffc658', width: '100%', height: '200px'}} elevation={12}>
              <CardContent>                                
                <Typography variant="h5" component="div" textAlign={"center"} color="text.secondary"> 
                  Overall Total for {previousMonthData.Create_time} 
                </Typography>
                <Typography variant="h1" component="div" textAlign={"center"}>{previousMonthData ? previousMonthData.Acuris + previousMonthData.Google + previousMonthData.Monitor : 0}</Typography>                                
              </CardContent>
            </Card>
          </Grid>
          )}          
      </Grid>
      <DataGrid 
        initialState={{
          sorting: { sortModel: [{field:'Create_time', sort: 'desc'}], },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        rows={notes} 
        pageSizeOptions={[10, 25, 50]}
        columns={searchColumns} 
        getRowId={(row) => row.Case} 
        autoHeight
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          },
        }}
      />
      <input style={{display: "none"}} id="select-upload-csv" type="file" 
        onChange={onFileChange}
        onClick={(event)=> {event.target.value = null}} 
      />
      {isAdmin && (
        <>
          <Button 
            sx={{ mt: 1, mx: 1 }} 
            variant="contained" 
            size="small" 
            endIcon={showAdminGraph ? <VisibilityOffIcon/> : <VisibilityIcon/>} 
            onClick={() => setShowAdminGraph(!showAdminGraph)}
          >
            {showAdminGraph ? 'Hide Graph' : 'Show Graph'}
          </Button>
          {showAdminGraph && <Box sx={{ margin:"auto", paddingTop: 2, height: 500, width: '100%' }}>  
          <ResponsiveContainer width="100%" height={400} position="relative">
            <BarChart data={Object.values(graphData)} margin={{bottom: 45}}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Create_time" height={70} label={{value: 'Month', offset: -10, position: 'insideBottom', dx: 40, dy: 40}} interval={0} tick={{ angle: -45, textAnchor: 'end' }} />
              <YAxis label={{ value: 'Total Searches', angle: -90, position: 'insideLeft', style:{textAnchor: 'middle'} }} />
              <Tooltip content={<CustomTooltip />} />
              <Legend wrapperStyle = {{marginBottom: "-80px"}} />
              {enableGoogle && (<Bar dataKey="Google" stackId="a" fill="#82ca9d" barSize={30} />)}
              <Bar dataKey="Acuris" stackId="a" fill="#277A8C" barSize={30} />
              <Bar dataKey="Monitor" stackId="a" fill="#8884d8" barSize={30} />
            </BarChart>
          </ResponsiveContainer>
          </Box>}
        </>
      )}
      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
    </Box>     
  )
}
