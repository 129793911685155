import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Notes from './pages/Notes'
import Create from './pages/Create'
import Monitor from './pages/Monitor'
import Audit from './pages/Audit'
import Admin from './pages/Admin'
import Layout from './components/Layout'
import {Login} from './components/Login'
import { Authenticator } from "@aws-amplify/ui-react";
import { RequireAuth } from './RequireAuth'
import MaintenanceModal from './pages/MaintenanceModal';
import React, { useState } from 'react'



function MyRoutes() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<RequireAuth><Notes /></RequireAuth>}/>            
          <Route path="/create" element={<RequireAuth><Create /></RequireAuth>}/>
          <Route path="/monitor" element={<RequireAuth><Monitor /></RequireAuth>}>
            <Route
              path=":Customer/:English_name/:Chinese_name"
              element={<Monitor />}
            />
          </Route>
          <Route path="/audit" element={<RequireAuth><Audit /></RequireAuth>}/>
          <Route path="/admin" element={<RequireAuth><Admin /></RequireAuth>}/>
          <Route path="/login" element={<Login />}/>          
        </Routes>
      </Layout>
    </Router>
  );
}

function App() {
  const [isMaintenanceModalOpen, setIsMaintenanceModalOpen] = useState(false);
  return (
    <Authenticator.Provider>
      <>
        <MaintenanceModal isOpen={isMaintenanceModalOpen} />
        <MyRoutes />
      </>
    </Authenticator.Provider>
  );
}

export default App;
