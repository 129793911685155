import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import TextField from '@mui/material/TextField'
import { useNavigate  } from 'react-router-dom'
// Picker packages. 
//install: npm i @mui/x-date-pickers
//install: npm i --save moment
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import { useAuthenticator } from '@aws-amplify/ui-react'
import Autocomplete from '@mui/material/Autocomplete'
import {currencyList} from './Lists/currencyList'
import {nationalityList} from './Lists/nationalityList'
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default function Create() {
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);
  //Date Picker does not have 'id' so that its state is handled separately
  const [DOB, setDOB] = useState(null);
  const [IDExpiry, setIDExpiry] = useState(null);
  //Create setState for English and Chinese name separately for switching required*
  const [Customer, setCustomer] = useState('');
  const [Type, setType] = useState('Individual');
  const [English_name, setEnglish_name] = useState('');
  const [Chinese_name, setChinese_name] = useState('');
  const [Email, setEmail] = useState('');
  const [Contact_number, setContact_number] = useState('');
  const [Mobile, setMobile] = useState('');
  const [Nationality, setNationality] = useState(null);
  const [IDType, setIDType] = useState('');
  const [IDNumber, setIDNumber] = useState('');
  const [Residential_address, setResidential_address] = useState('');
  const [Permanent_address, setPermanent_address] = useState('');
  const [Correspondence_address, setCorrespondence_address] = useState('');
  const [Tax_resident_country, setTax_resident_country] = useState('');
  const [Tax_number, setTax_number] = useState('');
  const [Tax_type, setTax_type] = useState('');
  const [Tax_country, setTax_country] = useState('');
  const [Annual_income_source_1, setAnnual_income_source_1] = useState('');
  const [Source_of_wealth_1, setSource_of_wealth_1] = useState('');
  const [Currency_1, setCurrency_1] = useState('');
  const [Estimated_networth_1, setEstimated_networth_1] = useState('');
  const [Annual_income_source_2, setAnnual_income_source_2] = useState('');
  const [Source_of_wealth_2, setSource_of_wealth_2] = useState('');
  const [Currency_2, setCurrency_2] = useState('');
  const [Estimated_networth_2, setEstimated_networth_2] = useState('');
  const [Annual_income_source_3, setAnnual_income_source_3] = useState('');
  const [Source_of_wealth_3, setSource_of_wealth_3] = useState('');
  const [Currency_3, setCurrency_3] = useState('');
  const [Estimated_networth_3, setEstimated_networth_3] = useState('');
  const [Employer_name, setEmployer_name] = useState('');
  const [Occupation, setOccupation] = useState('');
  const [Position, setPosition] = useState('');
  const [Proof_of_professional_investment_asset, setProof_of_professional_investment_asset] = useState('');
  const [Gender,setGender] = useState('');
  const genderList = ['','Male','Female'];
  // const [Remarks, setRemarks] = useState('');
  // const [Result, setResult] = useState('');
  const [Ongoing_monitor, setOngoing_monitor] = useState(false);
  //variables for insert to DynamoDB
  const config = require('../config.json');
  
  const navigate = useNavigate();

  const [English_name_error, setEnglish_name_error] = useState(false);
  const [English_name_errortext, setEnglish_name_errortext] = useState('');
  const [Customer_name_error, setCustomer_name_error] = useState(false);
  const [Customer_name_errortext, setCustomer_name_errortext] = useState('');

  const [notes, setNotes] = useState([]);
  const [monitorFreq, setMonitorFreq] = useState('Disable');  

  const fetchProfileData = async () => {
    try {
      if (user) {
        const response = await axios.get(
          `${config.api.invokeUrl}/profile`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `${user.signInUserSession.idToken.jwtToken}`,
            },
          }
        );

        if (response.status === 200) {
          const profileData = response.data;
          setMonitorFreq(profileData.Monitor_freq);
        }
      }
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        window.location.reload(true);
      }
      console.error('Error fetching profile data in layout:', error);
      setSnackbar({
        children: 'Fail to fetch the profile data in layout',
        severity: 'warning',
      });
    }
  };

  const fetchCases = async () => {
    try {
      const requestInfo = {
        headers: {
          "content-type": "application/json",
          Authorization: `${user.signInUserSession.idToken.jwtToken}`,
        },
        params: { User: `${user.attributes.email}` },
      };
      const res = await axios.get(`${config.api.invokeUrl}`, requestInfo);
      const Cases = res.data;
      setNotes(Cases);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(`An error has occurred: ${err}`);
    }
  };

  useEffect(() => {
    fetchCases();
    fetchProfileData();
    // need not refresh the case periodically because the Customer should not change
  }, []);

  useEffect(()=>{
    if (English_name.trim() == '' && Chinese_name.trim() == '') {
      setEnglish_name_error(true);
      setEnglish_name_errortext("Either English or Chinese is required.");
    }
    else {
      setEnglish_name_error(false);
      setEnglish_name_errortext('');
    }
  }, [English_name, Chinese_name]);

  const [English_name_label, setEnglish_name_label] = useState('Individual English name');
  const [Chinese_name_label, setChinese_name_label] = useState('Individual Chinese name');
  useEffect(
    () => {
      if (Type == 'Individual') {
        setEnglish_name_label('Individual English name')
        setChinese_name_label('Individual Chinese name')
      }
      else {
        setEnglish_name_label('Business English name')
        setChinese_name_label('Business Chinese name')
      }
    }, [Type]
  );

  const [Email_error, setEmail_error] = useState(false);
  const [Email_errortext, setEmail_errortext] = useState("");
  const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
  useEffect(() => {
    if (Email == "") {
      setEmail_error(false);
      setEmail_errortext("");
    } else if (emailRegEx.test(Email)) {
      setEmail_error(false);
      setEmail_errortext("Email is valid");
    } else {
      setEmail_error(true);
      setEmail_errortext("Email is not valid");
    }
  }, [Email]);

  const [Contact_number_error, setContact_number_error] = useState(false);
  const [Contact_number_errortext, setContact_number_errortext] = useState("");
  const telRegEx = /^(\+\d{1,3}[- ]?)?\d{1,}$/;
  useEffect(() => {   
    if (Contact_number == "") {
      setContact_number_error(false);
      setContact_number_errortext("");
    } else if (telRegEx.test(Contact_number)) {
      setContact_number_error(false);
      setContact_number_errortext("Contact number is valid.");
    } else {
      setContact_number_error(true);
      setContact_number_errortext("Start with + or number.");
    }
  }, [Contact_number]);
  
  const [Mobile_error, setMobile_error] = useState(false);
  const [Mobile_errortext, setMobile_errortext] = useState("");
  useEffect(() => {   
    if (Mobile == "") {
      setMobile_error(false);
      setMobile_errortext("");
    } else if (telRegEx.test(Mobile)) {
      setMobile_error(false);
      setMobile_errortext("Mobile is valid.");
    } else {
      setMobile_error(true);
      setMobile_errortext("Start with + or number.");
    }
  }, [Mobile]);

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (English_name.trim() == '' && Chinese_name.trim() == '') {
      setEnglish_name_error(true);
      setEnglish_name_errortext("Either English or Chinese is required.");
    } else if (English_name.match(/#/) || Chinese_name.match(/#/) || Customer.match(/#/)){ 
      //new error for  # restriction
      setEnglish_name_error(true);
      setEnglish_name_errortext("“#” is not allowed in Customer, English or Chinese name");

    } else if (Email != "" && !emailRegEx.test(Email)) {
      setEmail_error(true);
      setEmail_errortext("Email is not Valid");
    } else if (Contact_number != "" && !telRegEx.test(Contact_number)) {
      setContact_number_error(true);
      setContact_number_errortext("Start with + or number.");
    } else if (Mobile != "" && !telRegEx.test(Mobile)) {
      setMobile_error(true);
      setMobile_errortext("Start with + or number.");
    } else {
      setEnglish_name_error(false);
      setEnglish_name_errortext("");
      const userid = user.attributes.email;
      const headers = {
        "content-type": "application/json",
        Authorization: `${user.signInUserSession.idToken.jwtToken}`,
      };
      let caseid = ""; 
      if (Type == 'Individual') {
        caseid = "UI#" + userid + "#" + Customer.trim() + "#" + English_name.trim() + "#" + Chinese_name.trim();
      } else {
        caseid = "UB#" + userid + "#" + Customer.trim() + "#" + English_name.trim() + "#" + Chinese_name.trim();
      }    
      const params = {
        "User": userid,
        "Case": caseid,
        "Customer": Customer.trim(),
        "Type": Type,
        "English_name": English_name.trim(),
        "Chinese_name": Chinese_name.trim(),
        "Email": Email,
        "Contact_number": Contact_number,
        "Mobile": Mobile,
        "Nationality": Nationality && Nationality.code,
        "DOB": DOB && DOB.format("YYYY-MM-DD"),
        "Gender": Gender,
        "IDType": IDType,
        "IDNumber": IDNumber,
        "IDExpiry": IDExpiry && IDExpiry.format("YYYY-MM-DD"),
        "Residential_address": Residential_address,
        "Permanent_address": Permanent_address,
        "Correspondence_address": Correspondence_address,
        "Tax_resident_country": Tax_resident_country && Tax_resident_country.code,
        "Tax_number": Tax_number,
        "Tax_type": Tax_type,
        "Tax_country": Tax_country && Tax_country.code,
        "Annual_income_source_1": Annual_income_source_1,
        "Source_of_wealth_1": Source_of_wealth_1,
        "Currency_1": Currency_1 && Currency_1.code,
        "Estimated_networth_1": Estimated_networth_1,
        "Annual_income_source_2": Annual_income_source_2,
        "Source_of_wealth_2": Source_of_wealth_2,
        "Currency_2": Currency_2 && Currency_2.code,
        "Estimated_networth_2": Estimated_networth_2,
        "Annual_income_source_3": Annual_income_source_3,
        "Source_of_wealth_3": Source_of_wealth_3,
        "Currency_3": Currency_3 && Currency_3.code,
        "Estimated_networth_3": Estimated_networth_3,
        "Employer_name": Employer_name,
        "Occupation": Occupation,
        "Position": Position,
        "Proof_of_professional_investment_asset": Proof_of_professional_investment_asset,
        //"Remarks": Remarks,
        //"Result": Result,
        "Create_time": (new Date()).toISOString().slice(0, 19).replace('T', ' '), //Store in ISO time as import
        "Ongoing_monitor": Ongoing_monitor
      };
      // can't use ${userid} as it contains special character.
      await axios.post(`${config.api.invokeUrl}/userid`, params, { headers: headers })
        .then (response => {
          //console.log(response);
          //console.log(response.data);
          navigate('/');
        })
        .catch (async err => {
          if (err.response.status === 409)
          {
            setSnackbar({ children: 'Name ' + English_name.trim() + '  ' + Chinese_name.trim() + ' already exists in ' + 'Customer ' + Customer + '.', severity: 'warning' });
          }
          if (err.response.status === 401 || err.response.status === 403)
          {
            setSnackbar({ children: 'Please login again', severity: 'info' });
            await sleep(10*1000);
            window.location.reload(true);
            //navigate('/login');
            console.log(err);
          }
        })
    }
  };
  
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <Container size="sm">
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>      
        <Box></Box>
        <Grid container rowSpacing={3} spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="body1">Primary information</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormLabel id="controlled-type-group">Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="controlled-type-group"
              defaultValue="Individual"
              name="type-group"
              //value={Type}
              onChange={(e) => setType(e.target.value)} 
            >
             <FormControlLabel value="Individual" control={<Radio />} label="Individual" />
             <FormControlLabel value="Business" control={<Radio />} label="Business" />
            </RadioGroup> 
          </Grid>
        </Grid>
        <Grid container rowSpacing={3} spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
              // 1. Filter in notes array with defined Customer only. 2. map to array of Customer. 3. array to new Set for uniqueness 4. Change to Set to array by ...
              options= {[... new Set( notes.filter(option => typeof(option.Customer) != 'undefined').map(option => option.Customer) )]}
              freeSolo
              autoSelect
              onChange={(e, value)=>setCustomer(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //Limit the Customer length to 39 chars
                  inputProps={{ ...params.inputProps,maxLength: 39 }} 
                  id="Customer"
                  label="Customer"
                  fullWidth
                  error={Customer_name_error}
                  helperText={Customer_name_errortext}
                  variant="standard"
                  value={Customer}
                />
              )}
          />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="English_name"
              label={English_name_label}
              fullWidth
              variant="standard"
              error={English_name_error}
              helperText={English_name_errortext}
              value={English_name}
              onChange={(e) => setEnglish_name(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Chinese_name"
              label={Chinese_name_label}
              fullWidth
              variant="standard"
              value={Chinese_name}
              onChange={(e) => setChinese_name(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                id="DOB"
                label="Date of birth"
                format="YYYY-MM-DD"
                disableFuture={true}
                fullWidth
                slotProps={{ textField: { variant: 'outlined' } }}
                value={DOB}
                onChange={(newValue) => setDOB(newValue)}
                />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              id="Nationality"
              options={nationalityList}
              getOptionLabel={(option) => typeof option.code === 'string' || option instanceof String ? option.code: ''}
              isOptionEqualToValue={(option, value) => option.code === value.code || option.code === ''}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />                  
                  {option.name} ({option.code})
                </Box>
              )}
              fullWidth
              value={Nationality}
              onChange={(e, value) => setNationality(value)}
              renderInput={(params) => <TextField {...params} label="Nationality" variant='standard' />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Contact_number"
              label="Contact number"
              fullWidth
              variant="standard"
              error={Contact_number_error}
              helperText={Contact_number_errortext}
              value={Contact_number}
              onChange={(e) => setContact_number(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Mobile"
              label="Mobile"
              fullWidth
              variant="standard"
              error={Mobile_error}
              helperText={Mobile_errortext}
              value={Mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Email"
              label="Email"
              fullWidth
              variant="standard"
              type="Email"
              value={Email}
              error={Email_error}
              helperText={Email_errortext}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box pt={3} pb={0}>
          <Typography variant="body1">Identification document</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="IDType"
              label="Type"
              fullWidth
              variant="standard"
              value={IDType}
              onChange={(e) => setIDType(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="IDNumber"
              label="ID Number"
              fullWidth
              variant="standard"
              value={IDNumber}
              onChange={(e) => setIDNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              id="Gender"
              options={genderList}
              fullWidth
              value={Gender}
              onChange={(e, value) => setGender(value)}
              renderInput={(params) => <TextField {...params} label="Gender" variant='standard'/>}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                id="IDExpiry"
                label="Expiry"
                format="YYYY-MM-DD"
                fullWidth
                slotProps={{ textField: { variant: 'outlined' } }}
                value={IDExpiry}
                onChange={(newValue) => setIDExpiry(newValue)}
                />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Box pt={3} pb={0}>
          <Typography variant="body1">Address</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              id="Residential_address"
              label="Residential address"
              fullWidth
              rows={2}
              variant="standard"
              value={Residential_address}
              onChange={(e) => setResidential_address(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              id="Permanent_address"
              label="Permanent address"
              fullWidth
              rows={2}
              variant="standard"
              value={Permanent_address}
              onChange={(e) => setPermanent_address(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              id="Correspondence_address"
              label="Correspondence address"
              fullWidth
              rows={2}
              variant="standard"
              value={Correspondence_address}
              onChange={(e) => setCorrespondence_address(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box pt={3} pb={0}>
            <Typography variant="body1">Tax</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              id="Tax_resident_country"
              options={nationalityList}              
              getOptionLabel={(option) => typeof option.code === 'string' || option instanceof String ? option.code: ''}
              isOptionEqualToValue={(option, value) => option.code === value.code || option.code === '' }
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />                  
                  {option.name} ({option.code})
                </Box>
              )}
              fullWidth
              value={Tax_resident_country}
              onChange={(e, value) => setTax_resident_country(value)}
              renderInput={(params) => <TextField {...params} label="Tax resident country" variant='standard'/>}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Tax_number"
              label="Tax number"
              fullWidth
              variant="standard"
              value={Tax_number}
              onChange={(e) => setTax_number(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Tax_type"
              label="Tax type"
              fullWidth
              variant="standard"
              value={Tax_type}
              onChange={(e) => setTax_type(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              id="Tax_country"
              options={nationalityList}  
              getOptionLabel={(option) => typeof option.code === 'string' || option instanceof String ? option.code: ''}
              isOptionEqualToValue={(option, value) => option.code === value.code || option.code === '' }
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />                  
                  {option.name} ({option.code})
                </Box>
              )}
              fullWidth
              value={Tax_country}
              onChange={(e, value) => setTax_country(value)}
              renderInput={(params) => <TextField {...params} label="Tax country" variant='standard'/>}
            />
          </Grid>
        </Grid>
        <Box pt={3} pb={0}>
          <Typography variant="body1">Income and Wealth</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Annual_income_source_1"
              label="Annual income source 1"
              fullWidth
              variant="standard"
              value={Annual_income_source_1}
              onChange={(e) => setAnnual_income_source_1(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Source_of_wealth_1"
              label="Source of wealth 1"
              fullWidth
              variant="standard"
              value={Source_of_wealth_1}
              onChange={(e) => setSource_of_wealth_1(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              id="Currency_1" 
              options={currencyList}
              fullWidth
              value={Currency_1}
              onChange={(e, value) => setCurrency_1(value)}
              getOptionLabel={(option) => typeof option.code === 'string' || option instanceof String ? option.code: ''}
              isOptionEqualToValue={(option, value) => option.code === value.code || option.code === '' }
              renderOption={(props, option) => (
                <li {...props}>{option.name} ({option.code})</li> 
              )}
              renderInput={(params) => <TextField {...params} label="Currency 1" variant='standard'/>}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Estimated_networth_1"
              label="Estimated networth 1"
              type="number"
              variant="standard"
              fullWidth
              value={Estimated_networth_1}
              onChange={(e) => setEstimated_networth_1(e.target.value)}              
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Annual_income_source_2"
              label="Annual income source 2"
              fullWidth
              variant="standard"
              value={Annual_income_source_2}
              onChange={(e) => setAnnual_income_source_2(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Source_of_wealth_2"
              label="Source of wealth 2"
              fullWidth
              variant="standard"
              value={Source_of_wealth_2}
              onChange={(e) => setSource_of_wealth_2(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              id="Currency_2" 
              options={currencyList}
              fullWidth
              value={Currency_2}
              onChange={(e, value) => setCurrency_2(value)}
              getOptionLabel={(option) => typeof option.code === 'string' || option instanceof String ? option.code: ''}
              isOptionEqualToValue={(option, value) => option.code === value.code || option.code === '' }
              renderOption={(props, option) => (
                <li {...props}>{option.name} ({option.code})</li> 
              )}
              renderInput={(params) => <TextField {...params} label="Currency 2" variant='standard'/>}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Estimated_networth_2"
              label="Estimated networth 2"
              type="number"
              variant="standard"
              fullWidth
              value={Estimated_networth_2}
              onChange={(e) => setEstimated_networth_2(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Annual_income_source_3"
              label="Annual income Source 3"
              fullWidth
              variant="standard"
              value={Annual_income_source_3}
              onChange={(e) => setAnnual_income_source_3(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Source_of_wealth_3"
              label="Source of wealth 3"
              fullWidth
              variant="standard"
              value={Source_of_wealth_3}
              onChange={(e) => setSource_of_wealth_3(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              id="Currency_3" 
              options={currencyList}
              fullWidth
              value={Currency_3}
              onChange={(e, value) => setCurrency_3(value)}
              getOptionLabel={(option) => typeof option.code === 'string' || option instanceof String ? option.code: ''}
              isOptionEqualToValue={(option, value) => option.code === value.code || option.code === '' }
              renderOption={(props, option) => (
                <li {...props}>{option.name} ({option.code})</li> 
              )}
              renderInput={(params) => <TextField {...params} label="Currency 3" variant='standard'/>}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Estimated_networth_3"
              label="Estimated networth 3"
              type="number"
              variant="standard"
              fullWidth
              value={Estimated_networth_3}
              onChange={(e) => setEstimated_networth_3(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Employer_name"
              label="Employer name"
              fullWidth
              variant="standard"
              value={Employer_name}
              onChange={(e) => setEmployer_name(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Occupation"
              label="Occupation"
              fullWidth
              variant="standard"
              value={Occupation}
              onChange={(e) => setOccupation(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Position"
              label="Position"
              fullWidth
              variant="standard"
              value={Position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="Proof_of_professional_investment_asset"
              label="Proof of professional investment asset"
              type="number"
              fullWidth
              variant="standard"
              value={Proof_of_professional_investment_asset}
              onChange={(e) => setProof_of_professional_investment_asset(e.target.value)}
            />
          </Grid>
        </Grid>
        {(monitorFreq !== 'Disable') && (
          <>
            <Box pt={3} pb={0}>
              <Typography variant="body1">Review</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Ongoing monitor"
                    onChange={() => {
                      console.log(!Ongoing_monitor);
                      setOngoing_monitor(!Ongoing_monitor);
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </>
        )}

        <Box pt={3}>
          <Button
            type="submit" 
            color="secondary" 
            variant="contained"
            endIcon={<KeyboardArrowRightIcon />}>
            Submit
          </Button>
        </Box>
      </form>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Container>
  );
}
