import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { AddCircleOutlineOutlined, SubjectOutlined } from '@mui/icons-material'
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import logo from './AppbarLogo.png'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useAuthenticator } from "@aws-amplify/ui-react";
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ProfileModal from '../pages/ProfileModal'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography'

const config = require('../config.json')

const drawerWidth = 210

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Layout({ children }) {
  const [userEmail, setUserEmail] = useState('');
  const [isAdmin, setAdmin] = useState(false);
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const { user, route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);

  function logOut() {
    signOut();
    navigate("/login");
  }

  const theme = useTheme()
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate()
  const location = useLocation()
  const styles = {
    active: {
      background: '#f4f4f4'
    }
  }
  const menuItems = [
    { 
      text: 'New Case', 
      icon: <AddCircleOutlineOutlined color="secondary" />, 
      path: '/create' 
    },
    { 
      text: 'Cases Table', 
      icon: <SubjectOutlined color="secondary" />, 
      path: '/' 
    },
    { 
      text: 'Review Table', 
      icon: <RateReviewOutlinedIcon color="secondary" />, 
      path: '/monitor' 
    },
    { 
      text: 'Audit Trail', 
      icon: <TaskOutlinedIcon color="secondary" />, 
      path: '/audit' 
    },
    isAdmin && { 
      text: 'Administration', 
      icon: <SettingsRoundedIcon color="secondary" />, 
      path: '/admin' 
    },
  ];

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `KYCIC app note.pdf`;
    link.href = "KYCIC app note.pdf";
    link.click();
  };

  const [profileModalOpen, setProfileModalOpen] = useState(false);

  // Function to open the ProfileModal
  const openProfileModal = () => {
    setProfileModalOpen(true);
  };

  // Function to close the ProfileModal
  const closeProfileModal = () => {
    setProfileModalOpen(false);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        // Make an API call to get the profile data
        if (user) {
          const response = await axios.get(`${config.api.invokeUrl}/profile`, {
            headers: {
              "content-type": "application/json",
              Authorization: `${user.signInUserSession.idToken.jwtToken}`
            }
          });

          if (response.status === 200) {
            const profileData = response.data;
            setUserEmail(profileData.User_email || '');
            setAdmin(profileData.Admin);
            if (!profileData.Admin && (!profileData.Disclaimer_confirmed || !profileData.Profile_type || !profileData.BR_number || !profileData.Profile_contact_number)) {
              setProfileModalOpen(true);
            }
          }
        }
      } catch (error) {        
        if (error.response.status === 401 || error.response.status === 403) {
          //signOut();
          //navigate("/login");
          window.location.reload(true);
        }
        console.error('Error fetching profile data in layout:', error);
        setSnackbar({ children: 'Fail to fetch the profile data in layout', severity: 'warning' });
      }
    };

    // Call the fetchProfileData function when the component mounts
    fetchProfileData();
  }, [userEmail, user, profileModalOpen]); // Include user as a dependency

  return (
    <Box sx={{display: 'flex'}}>
      {/* app bar */}
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
        </IconButton>
        <Button 
          variant="text"
          style={{ fontSize: '22px', justifyContent: "flex-start" }} 
          sx={{
            "&.MuiButton-text": { color: "#424242" }}} 
          size="large" target="_blank" href="http://KYCIC.IO/">
          <Box component="img" src={logo} alt="KYCIC log" sx={{ maxHeight: 60, maxWidth: 60}} />
          KYCIC
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {route !== "authenticated" ?(
            <Button variant="contained" startIcon={<LoginIcon />}
              //sx={{"&.MuiButton-text": { color: "#424242" }, textTransform: 'none'}}
              onClick={() => navigate("/login")} >
              Login
            </Button>):(
            <Stack spacing={2} direction="row">
              <Button variant="text"
                sx={{"&.MuiButton-text": { color: "#424242" }, textTransform: 'none'}}
                size="medium" 
                endIcon={<SettingsRoundedIcon />}
                onClick={openProfileModal}
              >
                <Typography>
                  {isAdmin && user.attributes.email !== userEmail ? (
                    <>
                      Supporting{' '}
                      <span style={{ color: theme.palette.secondary.main }}>{userEmail}</span>
                    </>
                  ) : (
                    `Hi ${user.attributes.name}`
                  )}
                </Typography>
              </Button>
              <Button variant="contained" startIcon={<LogoutIcon />}
                //sx={{"&.MuiButton-text": { color: "#424242" }, textTransform: 'none'}}
                onClick={() => logOut()} >
                Logout
              </Button>
            </Stack>
            )
          }
          <Button onClick={onDownload} variant="text" color="secondary" startIcon={<LiveHelpOutlinedIcon style={{ fontSize: '26px', color: 'white' }}/>}>
          </Button>
        </Toolbar>
      </AppBar>

      <ProfileModal open={profileModalOpen} onClose={closeProfileModal} />
      
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {/* links/list section */}
        <List>
          {menuItems.map((item) => (
            <ListItem 
              button 
              key={item.text} 
              onClick={() => navigate(item.path)}
              sx = {location.pathname == item.path ? styles.active : null}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        
      </Drawer>

      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>

      {/* main content */}
      <Box sx={{background: '#f9f9f9', width: '100%', padding: theme.spacing(3)}}>
        <Box sx={theme => theme.mixins.toolbar} />
        { children }
      </Box>
    </Box>
  )
}
