// MaintenanceModal.js
import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import maintenanceImage from './maintenance-image.jpeg'; // Replace with your image path

const styles = {
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  modalContent: {
    background: '#fff',
    padding: '24px',
    width: '400px',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  imageContainer: {
    marginBottom: '16px',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  heading: {
    marginBottom: '16px',
  },
};

const MaintenanceModal = ({ isOpen }) => {
  return (
    <Modal open={isOpen} style={styles.modalContainer}>
      <div style={styles.modalContent}>
        <Box style={styles.imageContainer}>
          <img
            src={maintenanceImage}
            alt="Maintenance"
            style={styles.image}
          />
        </Box>
        <Typography variant="h5" style={styles.heading}>
          Maintenance Notice
        </Typography>
        <Typography>
          We are currently undergoing maintenance. Sorry for the inconvenience.
        </Typography>
      </div>
    </Modal>
  );
};

export default MaintenanceModal;
