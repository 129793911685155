import {Card, CardContent, CardActions, Typography, Grid} from '@mui/material'
import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useAuthenticator } from "@aws-amplify/ui-react"

const config = require('../config.json')

export default function Admin() {
  const { user } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);
  const [importFiles, setImportFiles] = useState([]);
  const [searchFiles, setSearchFiles] = useState([]);  
  const [isAdmin, setAdmin] = useState(false);

  const fetchTempFiles = async () => {
    try {
      const requestInfo = {
        headers: {
          "content-type": "application/json",
          Authorization: `${user.signInUserSession.idToken.jwtToken}`
        },
        params:{"User":`${user.attributes.email}`}
      }
      const res = await axios.get(`${config.api.invokeUrl}/dashboard`, requestInfo);
      const getImportFiles = res.data.Import;
      const getSearchFiles = res.data.Search;
      setImportFiles(getImportFiles);
      setSearchFiles(getSearchFiles);
    } catch (err) {  
      if (err.response.status === 401 || err.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(`An error has occurred: ${err}`);
    }
  }

  useEffect(() => {
    const fetchFiles = async () => {
      fetchTempFiles();
  
      const interval = setInterval(() => {
        fetchTempFiles();
      }, 1 * 60 * 1000);
  
      return () => clearInterval(interval);
    };
  
    const fetchProfileData = async () => {
      try {
        // Make an API call to get the profile data
        if (user) {
          const response = await axios.get(
            `${config.api.invokeUrl}/profile`,
            {
              headers: {
                "content-type": "application/json",
                Authorization: `${user.signInUserSession.idToken.jwtToken}`,
              },
            }
          );
  
          if (response.status === 200) {
            const profileData = response.data;
            setAdmin(profileData.Admin);
          }
        }
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          //signOut();
          //navigate("/login");
          window.location.reload(true);
        }
        console.error('Error fetching profile data in layout:', error);
      }
    };
  
    fetchFiles();
    fetchProfileData();   
  }, [user, isAdmin])

  return (
    <Grid container spacing={3}>
      {isAdmin && 
        <Grid item xs={12}>
          {searchFiles.length > 0 ? (
            <Typography variant="h5" gutterBottom sx={{ marginTop: '0.5rem' }}>
              Overall outstanding search activities
            </Typography>
          ) : (
            <Typography variant="h5" gutterBottom sx={{ marginTop: '0.5rem' }}>
              No outstanding search activity
            </Typography>
          )}
        </Grid>
      }
      {isAdmin && searchFiles.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card sx={{ maxWidth: 345 }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Running Search File
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Typography component="span" fontWeight="bold">File upload time:</Typography> {item.timestamp}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Typography component="span" fontWeight="bold">Length of time in storage:</Typography> {item.minutes_since_creation} minutes
              </Typography>
              <Typography variant="body2" color={item.search_type === 'A' ? 'primary' : 'secondary'}>
                <Typography component="span" fontWeight="bold">Search type:</Typography> {item.search_type === 'A' ? 'Acuris' : item.search_type === 'G' ? 'Google' : item.search_type}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {isAdmin && 
        <Grid item xs={12}>
          {importFiles.length > 0 ? (
            <Typography variant="h5" gutterBottom sx={{ marginTop: '0.5rem' }}>
              Overall pending import processes
            </Typography>
          ) : (
            <Typography variant="h5" gutterBottom sx={{ marginTop: '0.5rem' }}>
              No pending import process
            </Typography>
          )}
        </Grid>
      }
      {isAdmin && importFiles.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card sx={{ maxWidth: 345 }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Import WIP File
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Typography component="span" fontWeight="bold">File upload time:</Typography> {item.timestamp}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Typography component="span" fontWeight="bold">Length of time in storage:</Typography> {item.minutes_since_creation} minutes
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Typography component="span" fontWeight="bold">User:</Typography> {item.file_user}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid> 
  );
}