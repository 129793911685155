import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, TextField, Select, MenuItem, Grid } from '@mui/material';
import axios from 'axios';
import { useAuthenticator } from "@aws-amplify/ui-react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LogoutIcon from '@mui/icons-material/Logout';

const config = require('../config.json')
const ProfileModal = ({ open, onClose }) => {
  const [disclaimerConfirmed, setDisclaimerConfirmed] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userEmails, setUserEmails] = useState([]);
  const [type, setType] = useState('');
  const [origtype, setOrigType] = useState('');
  const [brNumber, setBRNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isAdmin, setAdmin] = useState(false);
  const [monitorFreq, setMonitorFreq] = useState('Disable');
  const [userEmailChanged, SetUserEmailChanged] = useState(false);
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const handleCheckboxChange = () => {
    setDisclaimerConfirmed(!disclaimerConfirmed);
  };
  const [scrollEnd, setScrollEnd] = useState(false);

  const isProfileFilled = () => {
    return type && brNumber && contactNumber;
  };

  const isSupporting = () => {
    if (user)
      return user.attributes.email !== userEmail;
    else
      return false;
  };

  const handleScroll = (e) => {
    const target = e.target;

    if (target.scrollHeight - target.scrollTop < target.clientHeight + 5) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);

  const handleClose = (event, reason) => {
    if (reason != 'backdropClick') {
      setTimeout(() => {
        onClose();
      }, 1000); // Wait for 1 sec so that logout process completed
      if (isAdmin || origtype != type)
        window.location.reload();
    }
  };

  const handleSave = async () => {
    // Create a data object to send to your API
    const data = {
      userEmail,
      type,
      brNumber,
      contactNumber,
      disclaimerConfirmed,
      monitorFreq
    };

    try {
      const response = await axios.patch(`${config.api.invokeUrl}/profile`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `${user.signInUserSession.idToken.jwtToken}`
        }
      });

      // Handle the response as needed
      //console.log(response.data);

    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.error('Error:', error);
      setSnackbar({ children: 'Fail to save the profile data', severity: 'warning' });
    }
    handleClose();
  };

  useEffect(() => {
    // Reset scroll position when modal is opened
    setScrollEnd(false);
  }, [open]);

  useEffect(() => {
    const SaveSupportingUserMail = async () => {
      const data = {
        userEmail,
      };
  
      try {
        const response = await axios.patch(`${config.api.invokeUrl}/profile`, data, {
          headers: {
            "content-type": "application/json",
            Authorization: `${user.signInUserSession.idToken.jwtToken}`
          }
        });
        SetUserEmailChanged(true)
      } catch (error) {

        if (error && error.response.status === 401 || error.response.status === 403) {
          //signOut();
          //navigate("/login");
          window.location.reload(true);
        }
        console.error('Error:', error);
        setSnackbar({ children: 'Fail to update the supporting email', severity: 'warning' });
      }
    }

    if (userEmail && isAdmin) {
      SaveSupportingUserMail()
    }
  }, [userEmail]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        // Make an API call to get the profile data
        const response = await axios.get(`${config.api.invokeUrl}/profile`, {
          headers: {
            "content-type": "application/json",
            Authorization: `${user.signInUserSession.idToken.jwtToken}`
          }
        });

        if (response.status === 200) {
          const profileData = response.data;
          setUserEmails(profileData.user_emails || []);
          setUserEmail(profileData.User_email || '');
          setType(profileData.Profile_type || '');
          setOrigType(profileData.Profile_type || '');
          setBRNumber(profileData.BR_number || '');
          setMonitorFreq(profileData.Monitor_freq || 'Disable');
          setContactNumber(profileData.Profile_contact_number || '');
          setAdmin(profileData.Admin || false);
          setDisclaimerConfirmed(profileData.Disclaimer_confirmed || false);
        }
        SetUserEmailChanged(false)
      } catch (error) {        
        if (error.response.status === 401 || error.response.status === 403) {
          //signOut();
          //navigate("/login");
          window.location.reload(true);
        }
        console.error('Error fetching profile data:', error);
        setSnackbar({ children: 'Fail to fetch the profile data', severity: 'warning' });
      }
    };

    // Fetch profile data when the modal is opened
    if ((open && user) || (isAdmin && userEmailChanged)) {
      fetchProfileData();
    }
  }, [open, userEmailChanged]);

  // Function to get initial monitor based on type
  const getInitialMonitor = (profileType) => {
    switch (profileType) {
      case 'MSO':
      case 'MSO premium':
        return 'Disable';
      case 'TCSP':
      case 'TCSP premium':
        return 'Semi-annually';
      case 'Money lender':
        return 'Quarterly';
      case 'SFC licensed corp':
      case 'Bank':
        return 'Daily';
      default:
        return 'Disable';
    }
  };

  useEffect(() => {
    if (type !== origtype) {
      // Update monitor based on selected type
      setMonitorFreq(getInitialMonitor(type));
    }
  }, [type]);

  return (
    <Modal open={open} onClose={handleClose} >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxHeight: '90vh', bgcolor: 'background.paper', boxShadow: 24, p: 2, overflow: 'auto' }}>
        <Typography variant="h5" gutterBottom>
          Profile
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* New TextField with MenuItem from userEmails */}
            {userEmails.length > 0 && (
            <TextField
              label="User email"
              select
              fullWidth
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              disabled={!isAdmin}
            >
              {userEmails.map((email) => (
                <MenuItem key={email} value={email}>
                  {email}
                </MenuItem>
              ))}
            </TextField>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Type"
              select
              fullWidth
              value={type}
              onChange={(e) => setType(e.target.value)}
              disabled={!isAdmin}
            >
              <MenuItem value="MSO">MSO</MenuItem>
              <MenuItem value="MSO premium">MSO premium</MenuItem>
              <MenuItem value="TCSP">TCSP</MenuItem>
              <MenuItem value="TCSP premium">TCSP premium</MenuItem>
              <MenuItem value="Money lender">Money lender</MenuItem>
              <MenuItem value="SFC licensed corp">SFC licensed corp</MenuItem>
              <MenuItem value="Bank">Bank</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Monitor"
              select
              fullWidth
              value={monitorFreq}
              onChange={(e) => {
                console.log("Event object:", e); // Log the entire event object
                console.log("Value:", e.target.value); // Log the value from the event target
                setMonitorFreq(e.target.value)
              }}
              disabled={!isAdmin}
            >
              <MenuItem value="Disable">Disable</MenuItem>
              <MenuItem value="Daily">Daily</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
              <MenuItem value="Quarterly">Quarterly</MenuItem>
              <MenuItem value="Semi-annually">Semi-annually</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="BR number"
              fullWidth
              value={brNumber}
              onChange={(e) => setBRNumber(e.target.value)}
              disabled={!isAdmin}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Contact number"
              fullWidth
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              disabled={!isAdmin}
            />
          </Grid>
        </Grid>
        <Box
            sx={{ mt: 2, maxHeight: '30vh', overflowY: 'auto' }}
            onScroll={handleScroll}
          >
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2, fontWeight: 'bold' }}>
            USER AGREEMENT:
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            THIS AGREEMENT is made on the consent given upon registration of account between each user of the web application (“Client”) and KYC Intelligent Compliance Limited (“KYCIC”).
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            1. DEFINED TERMS
            <br />
            1.1 In this Agreement:
            <br />
            <strong>Agreement </strong>means this agreement and the <a href="https://kycic.io/data-protection" target="_blank">Schedule</a> and hereby incorporated hereto.
            <br />
            <strong>Affiliate </strong>means, in respect of a company, a company which is its subsidiary or holding company, whether direct or indirect, or a company which is a direct or indirect subsidiary of that holding company and shall include such entities whether now existing or later established by investment, merger or otherwise, including the successors and assigns of such entities.
            <br />
            <strong>Applicable Period </strong>means the period in which this Agreement remains in force as decided by KYCIC from time to time;
            <br />
            <strong>Client </strong>means any third party entering into a contractual arrangement with KYCIC to obtain access to the Database and/or Services Content as a result of the operation of (including permissions granted by) this Agreement.
            <br />
            <strong>Confidential Information </strong>means any information belonging or relating to a Party (including its Affiliates) disclosed by or on behalf of that Party (the <strong>Disclosing Party</strong>) whether in writing, orally or by any other means, directly or indirectly, intentionally or unintentionally, before, on or after the date of this Agreement, relating to the business, activities, products, services, technology and financial information of either Party or its customers, employees or officers, and any other information of the Disclosing Party that is marked confidential or that the Receiving Party ought reasonably to have known was confidential, including but not limited to (i) proprietary or trade secret information, know-how, IPRs, algorithms, configurations, marketing, commercial, legal, operational, and administrative activities, pricing information, benchmarking studies, trading positions, strategy, specifications, designs, plans, drawings, hardware, software, data, prototypes, facilities, premises, systems, security, procedures, and (ii) any reports, copies, summaries, analyses, data, plans, forecasts, compilations, studies, notes, discussions, interpretations, memoranda and other documents which contain or otherwise reflect or are generated from any information specified in sub-paragraph (i) hereof and the contents thereof. The terms and conditions of this Agreement shall be Confidential Information of KYCIC.
            <br />
            <strong>Database </strong>means the KYC6 Database containing Services Content to which KYCIC grants access under the terms of this Agreement, as updated from time to time by information provided by KYCIC (and for the avoidance of doubt including any application programming interface and/or other technology from time to time provided by KYCIC to enable access to the Database).
            <br />
            <strong>Data Protection Laws </strong>has the meaning set out in the <a href="https://kycic.io/data-protection" target="_blank">Schedule</a>.
            <br />
            <strong>Effective Date </strong>means the date that this Agreement is made upon registration of account.
            <br />
            <strong>Fees </strong>mean the fees set out in a separate Fee Agreement between each Client and KYCIC.
            <br />
            <strong>IPR </strong>means copyright, moral rights, database rights, patents, patent applications, patent rights, rights to inventions, rights in designs, trademarks, trademark applications, trademark registrations, trademark rights, business names, rights in get-up and trade dress, goodwill and the right to sue for passing off domain names, trade secrets, in each case whether registered or unregistered, and all other intellectual property and proprietary information rights as may exist now or hereafter come into existence, all modifications, continuations, renewals and extensions of the foregoing, and all claims, actions, causes of action, damages, costs, expenses, profits, penalties, recoveries and remedies relating to any past, present or future infringement of any of the foregoing, arising under the laws of any country, state or jurisdiction in the world (and including any goodwill in any of the aforementioned).
            <br />
            <strong>Schedule </strong>means the Data Protection Schedule as displayed on the Data Protection tab at <a href="https://kycic.io/data-protection" target="_blank">https://kycic.io/data-protection</a> which may be updated from time to time to reflect the latest changes in relevant Data Protection Laws
            <br />
            <strong>Services Content </strong>means in relation to the Database the data sets, data feeds and other content contained in the Database, which has been obtained by KYCIC from third parties or generated in- house, presented in a format suitable for searching the information and using other functionality and including software operating it to enable Client to search the Database and use other functionality.
            <br />
            <strong>Service IPR </strong>means, in relation to the Database and/or Services Content, all IPR in the same to the extent that such IPR belongs to KYCIC.
            <br />
            <strong>Term </strong>means the term of this Agreement from the Effective Date until the ending of this Agreement in accordance with Clause 9.1.
            <br />
            <strong>Terms and Conditions </strong>means these terms and conditions (including the Schedule), which follow the Schedule.
            <br />
            1.2 Clause, schedule and paragraph headings do not affect the interpretation of this Agreement. A reference to a Clause or the Schedule is a reference to a clause of, or the schedule to, this Agreement. A reference to a paragraph is to a paragraph of the relevant schedule, and a reference to an appendix is to the relevant appendix to this Agreement. Unless the context otherwise requires, a reference to one gender includes a reference to the other genders. A reference to a particular statute, statutory provision or subordinate legislation is a reference to it as it is in force from time to time, taking account of any amendment or re-enactment and includes any statute, statutory provision or subordinate legislation which it amends or re-enacts and subordinate legislation for the time being in force made under it. Where the words include(s), including or in particular are used in this Agreement, they are deemed to have the words without limitation following them.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            2. SCHEDULE
            <br />
            2.1 In the event of any conflict between the Schedule and these terms and conditions, these terms and conditions will take precedence unless otherwise expressly provided by the Schedule (in which case the Schedule shall take precedence, but only for the purpose of that Schedule and no other Schedule).
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            3. USE OF DATABASE
            <br />
            3.1 Subject to the provisions of this Agreement, and in consideration of the proper payment by the Client to KYCIC of the Fees, KYCIC grants the Client for the Term a non-exclusive, non-transferable, non-assignable, non-sublicensable (save as expressly permitted in this Agreement) to:
            <br />
            a) access the Database; and
            <br />
            b) use Services Content solely for the proper purposes of conducting Client’s business.
            <br />
            3.2 The Database and Services Content shall be used only by the Client; 
            <br />
            3.3 Except to the extent permitted by this Agreement, the Client shall not:
            <br />
            a) market or exploit the Database and/or Services Content or make it available to another person or permit another person to use or access it;
            <br />
            b) alter or modify the Database and/or Services Content;
            <br />
            c) copy, reverse engineer, decompile or disassemble the Database and/or Services Content or any part of it or them;
            <br />
            d) charge or otherwise deal in or encumber the Database and/or Services Content;
            <br />
            e) delete, remove or in any way obscure KYCIC's proprietary notices on any copy of the Database and/or Services Content;
            <br />
            f) in any circumstances use, nor instruct, facilitate or otherwise influence any third party to access the Database and/or Services Content (including via any automated software, process, programme, robot, web crawler, spider, data mining, trawling, screen scraping or other similar software, regardless of whether or not the resulting information would then be used for their internal purposes) to gain unauthorised access to the Database and/or Services Content;
            <br />
            g) use the Database and/or Services Content in any way which might infringe any licence granted to Client, any law (including criminal and/or Data Protection Law) and/or use the Database and/or Services Content for any unlawful and/or unauthorised purpose;
            <br />
            h) use the name, trademarks, service marks, logos, domain names, or any other identifiers of KYCIC in any way without prior written approval of the lawful owner of such identifier in each instance;
            <br />
            i) remove any copyright or other notice contained or included in the Database and/or Services Content;
            <br />
            j) use the Database and/or Services Content to compete in any way with KYCIC and/or any licensor of KYCIC;
            <br />
            k) copy any of the look and feel of the software and/or the business logic or functionality of the software in the Database and/or Services Content;
            <br />
            3.4 The Client acknowledges and agrees that it is responsible and liable for all acts and omissions of Client’s user in connection with the KYCIC’s user interface. The Client undertakes to:
            <br />
            a) monitor the use of the KYCIC’s user interface for any activity that breaches applicable laws, rules, and regulations or any terms of this Agreement, including any fraudulent, inappropriate, or potentially harmful behaviour, and promptly restrict any offending users of the KYCIC’s user interface from its future use;
            <br />
            b) use all reasonable endeavours to secure the KYCIC’s user interface from infringement, misappropriation, theft, misuse of unauthorised access; and
            <br />
            c) use all reasonable endeavours to prevent the introduction of any virus or vulnerability into the Database via any use of the KYCIC’s user interface.
            <br />
            3.5 The Client acknowledges that the Database and the Services Content is licensed, not sold, and that it obtain(s) no ownership of the Database or any Services Content.
            <br />
            3.6 The Client undertakes, promptly upon becoming aware of any breach of this Clause 3, to notify KYCIC thereof, and promptly provide such detail as KYCIC may reasonably request.
            <br />
            3.7 The Client shall ensure that the Client’s users abide by the obligations, restrictions and limitations set out in this Agreement (including the provisions of Clauses 3.2 to 3.6 inclusive, this Clause 3.7, 6.1 h) to 6.1.j) inclusive, 8 and 11 (the provisions listed in this Clause 3.7 being the Key Terms)), and accordingly the Client acknowledges and agrees that compliance with the Key Terms is of fundamental importance to KYCIC and its protection of its IPR. Where KYCIC (acting reasonably) determines that the Client is in breach of Clause 3.3, Clause 3.4 and/or this Clause 3.7, such breach will be deemed a material breach incapable of remedy of this Agreement and the provisions of Clause 9.2.a) will apply.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            4. FEE ARRANGEMENT
            <br />
            4.1 The Client shall pay the Fees to KYCIC within 30 (thirty) days of issue of KYCIC’s invoice.
            <br />
            4.2 During the Applicable Period of the Agreement, KYCIC may increase the Fees as it thinks appropriate with 30 days prior notice. 
            <br />
            4.3 The Fees do not include any value added tax or other applicable taxes, which the Client may be required to pay in addition.
            <br />
            4.4 Without prejudice to Clause 4.5 and/or any of KYCIC’s other rights, if the Client fails to pay the Fees when they are due, KYCIC may charge the Client interest at either (at KYCIC’s sole option): (i) 10% per annum of the total sum due; or (ii) the maximum rate permitted by law; compounded daily from the due date for payment until the date on which the obligation of the Client to pay the sum is discharged in full (whether before or after judgment). The Parties agree that such sum has been agreed commercially and in good faith as a reasonable pre-estimate of such loss by way of liquidated damages.
            <br />
            4.5 Failure to pay Fees when due shall be a material breach of this Agreement, and the provisions of Clause 9.2.a) shall apply. Without prejudice to any other right or remedy it may have, KYCIC reserves the right to set off any amount owing at any time to it by the Client against any amount payable by KYCIC to the Client and to apply an additional reactivation fee upon resolution at KYCIC’s discretion.
            <br />
            4.6 The Client shall not be entitled to withhold Fees on the grounds that there are pending complaints or disputes.
            <br />
            4.7 The Parties agree that the Fees have been agreed commercially and in good faith.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            5. REPRESENTATIONS AND WARRANTIES
            <br />
            5.1 KYCIC warrants at the Effective Date that:
            <br />
            a) it is entitled to grant the licenses set out in this Agreement;
            <br />
            b) use by the Client of any Service IPR will not infringe the IPR of any third party; and
            <br />
            c) it will perform its obligations under this Agreement with reasonable care and skill.
            <br />
            5.2 If the Client gives KYCIC notice of a breach of the warranties in Clause 5.1, KYCIC shall use all reasonable endeavours to remedy the defect. This shall be the Client’s sole and exclusive remedy for a breach of any warranty in Clause 5.1.
            <br />
            5.3 Except as expressly set out in this Agreement, KYCIC makes no warranty or representation. To the maximum extent permitted by law, and except for the warranties expressly set forth herein, KYCIC disclaims any and all other warranties and conditions, whether express, implied, or statutory, including but not limited to implied warranties (if any) of merchantability, fitness for a particular purpose, intellectual property right infringement and satisfactory quality.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            6 RIGHTS AND OBLIGATIONS
            <br />
            6.1 The Client shall:
            <br />
            a) give KYCIC and its representatives, promptly upon KYCIC’s reasonable notice, such access to premises, personnel, software, equipment, information, and records as KYCIC requires to perform its obligations under this Agreement, to verify the accuracy of Fees charged and/or paid, and/or to verify that the Client is complying with its obligations under this Agreement provided that KYCIC complies at all times with the Client’s internal policies (including security and health and safety policies) in force from time to time. KYCIC shall have no liability for a failure to perform its obligations to the extent that such failure is due to the Client denying such access to KYCIC;
            <br />
            b) if KYCIC conducts an audit of the Client’s compliance with its obligations under this Agreement that reveals that the Client is in breach of those obligations, reimburse KYCIC in respect of its reasonable costs incurred in carrying out that audit;
            <br />
            c) provide KYCIC with such facilities and assistance, as KYCIC requires to perform its obligations under this Agreement;
            <br />
            d) comply with any operating instructions of which KYCIC has given the Client reasonable notice;
            <br />
            e) remain solely responsible for the use of the Database and/or any Services Content;
            <br />
            f) prohibit any person who is not a Client’s user from using the Database and/or any Services Content;
            <br />
            g) not, and procure that Client’s users do not, access or use the Database and/or Services Content in any manner that constitutes unauthorized access to or use of the Database and/or Services Content;
            <br />
            h) prevent unauthorized access to or use of the Database and/or Services Content and promptly notify KYCIC of any unauthorized access to or use of the Database and/or Services Content and any loss or theft or unauthorized use of any Client’s password or username;
            <br />
            i) ensure that usernames and passwords for the Database and/or Services Content are not shared or used by more than one Client’s user; and
            <br />
            k) not, during the Term and continuing for a period of 2 (two) years following termination of this Agreement, solicit the employment or hire any (i) current employee of KYCIC or its Affiliates; or (ii) any person who was an employee of KYCIC or of its Affiliates within the immediately preceding 12 (twelve) month period. Nothing in this Clause shall prevent the Client hiring any employee or former employee of KYCIC or its Affiliates provided such employee is not utilized in connection with (a) the provision of any service provided under this Agreement and/or any similar service, or (b) any content similar to or related to any Services Content; in each case on commencement of employment or at any time during the period of 1 (one) year following commencement of employment.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            7  DATA PROTECTION
            <br />
            7.1 The IPRs and all right, title and interest in the IPRs in the Database and all Services Content shall remain at all times vested in KYCIC and/or its Affiliates and/or licensors. The IPRs in any services delivered pursuant to this Agreement are, and shall remain at all times, vested in KYCIC or its Affiliates and/or licensors. The Client acknowledges and agrees that (a) all present and future rights in and the Database and all Services Content, including the right to grant access to any of them via any present or future technology, are solely and exclusively reserved to KYCIC and/or its licensors, and (b) that the Database and the Services Content are not in the public domain; and (c) that the Database and the Services Content have been developed by KYCIC through substantial amounts of work, time and expense invested in obtaining and presenting its contents.
            <br />
            7.2 The Client grants KYCIC the right to compile, process and store any data, metadata, user behaviour and analytics Client provides to KYCIC in order to provide and enhance KYCIC’s provision of the Database and the Services Content. The Client acknowledges and agree that KYCIC and its Affiliates may also use such data for their business purposes including but not limited to support, product development and enhancement, including the development and enhancement of derivative products, statistical analysis, billing and reporting, provided however that KYCIC may only use the Client’s data for the fulfillment of this Agreement, unless the Client’s data is aggregated with the data of other clients such that the identification of any particular client cannot be ascertained (such data being Obfuscated Data). All IPRs, right, title and interest in Obfuscated Data, including any products derived from or enhanced with Obfuscated Data, shall at all times be and shall remain vested with KYCIC.
            <br />
            7.3 The Parties agree that all Personal Data will be processed in accordance with the Data Processing Agreement in the Schedule.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            8. LIMITATION OF LIABILITIES
            <br />
            8.1 Subject to Clause 8.6, neither Party shall be liable to the other or to any third party for any: (a) loss of software, loss of or corruption to data, loss of profits or revenues, trading losses, fines, loss of contracts, loss of operation time, loss of goodwill or anticipated savings; or (b) any indirect, special or consequential loss, damage, costs or expense of any kind of whatever nature, even if foreseeable or if a Party has been advised of their possibility and in all cases however caused and whether arising under or in connection with this Agreement, tort (including negligence), breach of statutory duty or otherwise. For the avoidance of doubt, the Client’s liability for Fees due or payable by the Client lost by KYCIC as a result of the Client’s breach of this Agreement are not excluded under this Clause 8.1.
            <br />
            8.2 The Client acknowledges that KYCIC compiles data but does not originate it. Accordingly, the Database and the Services Content are provided on an “as is and as available” basis and KYCIC does not make any representations or warranties of any kind, whether statutory, express or implied, relating to the Database and the Services Content (including relating to the timeliness, currency, continuity, accuracy, completeness, merchantability, non-infringement or fitness for a particular purpose thereof). In particular, no representation and/or warranty is given that the provision of such data by KYCIC complies with any law.
            <br />
            8.3 The Client acknowledges that every business decision involves the assumption of a risk and that KYCIC, in furnishing the Database and Services Content to the Client, does not and will not underwrite that risk, in any manner whatsoever. KYCIC shall not be liable to the Client or any third party for any loss caused in whole or in part by KYCIC’s procuring, compiling, collecting, interpreting, reporting, communicating, supplying or delivering the Database and/or the Services Content and the Client agrees not to bring any claim against KYCIC and/or its data suppliers in respect thereof.
            <br />
            8.4 The Client acknowledges that:
            <br />
            a) use of the Database and/or the Services Content by it is at its sole risk;
            <br />
            b) whilst KYCIC obtains data in the Database and/or the Services Content in good faith from sources which KYCIC considers to be reliable, such data is supplied to KYCIC by third parties and is not independently verified by KYCIC;
            <br />
            c) KYCIC does not guarantee the sequence, accuracy, completeness and/or timeliness of the Database and/or the Services Content;
            <br />
            d) neither the Database nor the Services Content are intended to and do not provide tax, legal or investment advice;
            <br />
            e) the Client should seek independent tax, legal and/or investment advice before acting on data obtained from the Database and/or Services Content; and
            <br />
            f) KYCIC shall not be under and excludes to the fullest extent permitted by law all liability to the Client for any liability whatsoever in respect of (i) any mistakes, errors, inaccuracies or omissions in, or incompleteness of, the Database and/or Services Content, (ii) delays in updating any Database and/or Services Content or nonavailability thereof (iii) loss of profit, business revenue, goodwill and anticipated savings (whether direct or indirect) incurred through the use of any Database and/or Services Content; (iv) trading, investment or other losses which Client may incur as a result of use of or reliance upon the Database and/or Services Content (iv) internet failure, and/or (v) failure of the Client to have in place any necessary software or equipment; and/or (vii) any claim that the provision of the Database and/or Services Content infringes any law.
            <br />
            8.5 The entire liability of KYCIC under or in connection with this Agreement shall at all times be limited to an amount no greater in the aggregate than the total amount of Fees paid to it in the preceding twelve months.
            <br />
            8.6 Notwithstanding any other provision of this Clause 8, nothing in this Agreement shall operate to exclude or restrict either Party’s liability for death or personal injury resulting from negligence, or any other liability to the extent that the same may not be limited or excluded by the laws governing this Agreement.
            <br />
            8.7 The Client shall indemnify KYCIC against all loss, liability, cost and expense (including reasonable legal expenses) incurred by KYCIC and/or any Affiliate as a result:
            <br />
            a) the use of the Database and/or Services Content in any manner not expressly authorised under the terms of this Agreement; and/or
            <br />
            b) any breach by the Client of any obligation imposed upon it in accordance with the terms of this Agreement (including the Key Terms).
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            9. TERM AND TERMINATION
            <br />
            9.1 The Agreement will remain in force for the Initial Period and then automatically renew for additional terms each equal in duration to the Initial Period (each such additional term an Additional Period) unless terminated by either Party by notice to the other Party no less than 90 (ninety) days prior to the date of the expiration of the then current Initial Period or Additional Period. Such termination shall be effective at the end of the then current Initial Period or Additional Period. The provisions of this clause 9.1 are subject at all times to earlier termination in accordance with clause 9.2.
            <br />
            9.2 A Party may terminate this Agreement with immediate effect by notice to the other Party in the event of:
            <br />
            a) the other Party being in breach of a material obligation under this Agreement and such breach is incapable of remedy or, if the breach is capable of remedy, the breaching Party failing to remedy the breach within 30 (thirty) days starting on the day after receipt of notice from the first Party giving details of the breach and requiring the other Party to remedy the breach; or
            <br />
            b) the other Party passing a resolution for its winding up, a court making an order for the other Party's winding up or dissolution, the making of an administration order in relation to the other Party (or a legally analogous event in any jurisdiction), or the other Party making an arrangement or composition with its creditors generally or making an application to a court for protection from its creditors generally (or any legally analogous event in any jurisdiction).
            <br />
            9.3 Upon termination:
            <br />
            a) all licences granted under Clause 3.1 shall terminate;
            <br />
            b) the Client shall, in addition to complying with its obligation under Clause 11.8 in respect of Confidential Information, cease using the Database and Services Content, and within 10 (ten) days of termination of the Agreement the Client shall deliver to KYCIC or delete or destroy all copies of the Database and Services Content and shall certify in writing to KYCIC that none of them and, except as may be required by law, no copies of them have been retained by the Client or any other person;
            <br />
            c) the Client shall promptly pay KYCIC all Fees and charges due at the date of termination;
            <br />
            d) the provisions of Clauses 4, 5.3, 6.11, 7.3, 8, 9, 11 and 14 shall survive such termination; and
            <br />
            9.4 The rights or liabilities of either Party which may have accrued up to the date of termination shall remain unaffected.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            10. MATERIAL ADVERSE EVENTS
            <br />
            10.1 If a Party is prevented, hindered or delayed from or in performing any of its obligations under this Agreement by an event beyond its reasonable control (including, without limitation, strike, lock-out, labour dispute, act of God, war, riot, civil commotion, epidemic or pandemic, malicious damage, compliance with a law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood and storm), that Party's obligations under this Agreement are suspended while the event continues and to the extent that it is prevented, hindered or delayed. For the avoidance of doubt, no such suspension shall be granted to any obligation under this Agreement to pay KYCIC.
            <br />
            10.2 If an event of the kind referred to in Clause 10.1 continues to prevent, hinder or delay the performance of a Party’s obligations under this Agreement and/or Agreement in a material respect for more than 30 (thirty) days, the other Party may terminate this Agreement with immediate effect by notice to the affected Party.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            11. CONFIDENTIAL INFORMATION
            <br />
            11.1 The Receiving Party shall not use Confidential Information for a purpose other than the performance of its obligations or enforcement of its rights under this Agreement and shall not disclose Confidential Information to any third party except with the prior written consent of the Disclosing Party or in accordance with Clause 11.3.
            <br />
            11.2 The Receiving Party shall not disclose Confidential Information except to any of its officers, employees, agents, professional advisors and contractors (Authorised Representatives) provided that disclosure is necessary for the purposes of this Agreement, and such disclosee is bound to obligations of confidentiality at least as stringent as those provided under this Agreement.
            <br />
            11.3 Clauses 11.1 and 11.2 do not apply to Confidential Information which:
            <br />
            a) is at the Effective Date, or at any time after that date becomes publicly known other than by the Receiving Party's breach of this Agreement; or
            <br />
            b) can be shown by the Receiving Party to the Disclosing Party's reasonable satisfaction to have been known by the Receiving Party before disclosure by the Disclosing Party to the Receiving Party or subsequently to have been disclosed to the Receiving Party by a source other than the Disclosing Party without breach of this Agreement.
            <br />
            c) KYCIC reserves the right to collect, use, and analyze metadata, user behavior, and analytics arising from the Client’s use of the Database and Services Content. KYCIC may use such information to improve its services, develop new products, perform statistical analysis, and for any other business purpose, provided that any use of Client data shall be anonymized and aggregated, ensuring that the Client’s identity cannot be directly identified from such information.
            <br />
            11.4 Notwithstanding anything to the contrary, the Receiving Party may share Confidential Information in the limited circumstance that it is required to disclose by law or by a competent regulatory authority, such Confidential Information, provided however, the Receiving Party making such disclosure must:
            <br />
            a) first notify the Disclosing Party as soon as practicable upon becoming aware of any such requirement; and
            <br />
            b) co-operate with the Disclosing Party (at the Disclosing Party’s reasonable expense) to avoid or limit disclosure and to gain assurances as to confidentiality from the authority to whom the information is to be disclosed.
            <br />
            11.5 Each Party will expressly inform its Authorised Representatives of the confidential nature of the Confidential Information of the Disclosing Party and the purpose for which it may be used and will procure their compliance with the terms of this Agreement as if they were a party to it. The Receiving Party shall be responsible for any breach of this Agreement by any of its Authorised Representatives, and, at its sole expense, shall take all reasonable measures to restrain its Authorised Representatives from prohibited or unauthorised disclosure or use of the Confidential Information.
            <br />
            11.6 The Receiving Party shall not use the Confidential Information in any manner that could compete with the business of the Disclosing Party.
            <br />
            11.7 Confidential Information shall remain the exclusive property of the Disclosing Party. Except as specifically provided for herein, nothing in this Agreement shall be construed as granting or conferring any rights by licence or otherwise in any Confidential Information of the Disclosing Party.
            <br />
            11.8 Within 10 (ten) days of termination of this Agreement the Receiving Party shall:
            <br />
            a) return to the Disclosing Party or destroy all documents and materials (including electronic media) or such parts thereof as contain or relate to any Confidential Information, together with any copies which are in the Receiving Party’s possession, custody or control or are in the possession, custody or control of any of its Authorised Representatives, provided that such information is in a form which is capable of delivery or destruction; and
            <br />
            b) permanently erase all Confidential Information from any computer, word processor, mobile telecommunications device or similar device by or on behalf of the Receiving Party or by or on behalf of its Authorised Representatives.
            <br />
            11.9 The Receiving Party may retain: (i) one copy of the Confidential Information for the purposes of and for so long as required by any law, court or regulatory agency or authority or its internal compliance procedures; and (ii) electronic files containing Confidential Information created pursuant to automatic archiving and back-up procedures.
            <br />
            11.10 The Receiving Party acknowledges that neither the destruction, return nor deletion of any Confidential Information will release it from the obligations contained in this Agreement .
            <br />
            11.11 The Receiving Party recognises that irreparable harm can be occasioned to the Disclosing Party by breach of this Agreement and by the unauthorised disclosure, reproduction or use of the Confidential Information by it or its Authorised Representatives and that monetary damages will be inadequate to compensate the Disclosing Party for such breach. The Receiving Party agrees that in the event of such breach, the Disclosing Party may be entitled to apply for a preliminary injunction to protect and recover the Confidential Information. This remedy shall be in addition to any other remedies available to the Parties under this Agreement or at law.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            12. NOTICES
            <br />
            12.1 All notices given under or in connection with this Agreement shall be in writing in the English language.
            <br />
            12.2 Notices to:
            <br />
            a) KYCIC shall be delivered to KYCIC’s address which KYCIC may have from time to time specified in a notice by (i) hand or (ii) post or in the invoice sent to the Client.
            <br />
            b) the Client shall be delivered to the Client’s address which the Client may have from time to time specified in a notice or any billing address provided by the Client from time to time by (i) hand or (ii) post or (iii) as otherwise agreed by the Parties in writing.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            13.  GENERAL PROVISIONS
            <br />
            13.1 This Agreement constitutes the entire agreement, and supersedes any previous agreements, between the Parties relating to the subject matter of this Agreement. The Client acknowledges that it has not relied on or been induced to enter this Agreement by any representation other than those expressly set out in this Agreement. KYCIC is not liable to the Client (in equity, contract or tort, or in any other way) for a representation (other than a fraudulent misrepresentation) that is not set out in this Agreement.
            <br />
            13.2 To the extent not already terminated, any prior agreement between KYCIC and the Client relating to the Database and/or Services Content is hereby terminated.
            <br />
            13.3 A variation of this Agreement is valid only if it is in writing and agreed by or on behalf of each Party.
            <br />
            13.4 The failure by either Party to exercise or delay in exercising a right or remedy provided by this Agreement or by law does not constitute a waiver by such Party of the right or remedy or a waiver of other rights or remedies. No single or partial exercise by either Party of a right or remedy provided by this Agreement or by law prevents further exercise of the right or remedy or the exercise of another right or remedy.
            <br />
            13.5 In this Agreement, a reference to a person includes a reference to a body corporate, association or partnership and to that person's legal personal representatives, successors and lawful assigns.
            <br />
            13.6 This Agreement is personal to the Client, and it shall not assign or otherwise transfer its rights or obligations under this Agreement without the prior written consent of KYCIC save that the Client may assign its rights and obligations under this Agreement (in whole but not in part) to any of its Affiliates.
            <br />
            13.7 This Agreement is not intended to confer any right or benefit on any person who is not party to it. A person who is not party to this Agreement has no right to enforce any term, condition or other provision of this Agreement.
            <br />
            13.8 This Agreement shall not constitute or imply any partnership, joint venture, agency, fiduciary relationship or other contractual relationship unless expressly provided for in this Agreement.
            <br />
            13.9 If any provision of this Agreement is prohibited by law or judged by a court to be unlawful, void or unenforceable, the provision shall to the extent required be severed from this Agreement and rendered ineffective as far as possible without modifying the remaining provisions of this Agreement , and shall not in any way affect any other circumstances of or the validity or enforcement of this Agreement . The Parties hereby agree to attempt to substitute for any invalid, unlawful or unenforceable provision a valid lawful or enforceable provision which achieves to the greatest extent possible the economic, legal and commercial objectives of the invalid, unlawful or unenforceable provision.
            <br />
            13.10 This Agreement may become effective upon registration of username and password at the web application and this has the same effect as if the signatories were on a single copy of this Agreement . Execution and delivery of this Agreement by exchange of copies (including electronically) upon confirmation of the Client shall constitute a valid and binding execution and delivery of this Agreement by the Client to KYCIC.
            <br />
            13.11 This Agreement is the result of negotiations between the Parties, each of which is a sophisticated business entity commercially familiar with the subject matter of the Agreement. In the event an ambiguity or question of intent or interpretation arises, this Agreement shall be construed as if drafted jointly by the Parties and no presumption or burden of proof shall arise favouring or disfavouring any Party by virtue of the authorship of any provisions of this Agreement.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 2 }}>
            14. JURISDICTION
            <br />
            14.1 This Agreement is governed by, and shall be construed in accordance with, the laws of Hong Kong.
            <br />
            14.2 Each Party shall comply with all applicable law, rules and regulations in respect of all activities conducted under this Agreement.
            <br />
            14.3 Subject to Clause 14.4, the Parties consent to non-exclusive jurisdiction of the courts of Hong Kong.
            <br />
            14.4 Nothing in Clause 14.3 shall prevent either Party from seeking injunctive relief in the courts of any jurisdiction for the breach, or threatened breach, of this Agreement.
          </Typography>
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={disclaimerConfirmed}
              onChange={handleCheckboxChange}
              color="primary"
              disabled={!scrollEnd}
            />
          }
          label="I have read, understood and consent to the User Agreement herein."
        />

        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {!(type && brNumber && contactNumber) && (
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <p style={{ backgroundColor: 'yellow', padding: '10px', borderRadius: '5px', fontWeight: 'bold' }}>Email to <a href="mailto:general@kycic.io?subject=kycic.io%20registration">general@kycic.io</a> with BR and contact number to enable the service</p>
            </div>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button variant="contained" color="primary" disabled={!(isAdmin || (disclaimerConfirmed && isProfileFilled()))} onClick={handleSave}>
            OK
          </Button>
          {!isProfileFilled() && !isAdmin && (
            <Button variant="contained" startIcon={<LogoutIcon />} onClick={() => { signOut(); handleClose(); }}>
              Logout
            </Button>
          )}
          {isAdmin && (
            <Button variant="contained" onClick={(e) => { e.preventDefault(); handleClose(); }}>
              Cancel
            </Button>
          )}
        </Box>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </Modal>
    );
};

export default ProfileModal;
